import React from 'react'
import Button from '../button/button.js'
import "./hero.css"

const Hero = (props) => {
    return (
        <div className='outer hero-body-outer bg-orange' id="services">
            <div className='inner hero-body-inner'>

                <div className='hero-body-img'>
                    <img className='dropshadow' src={props.img} />
                </div>

                <div className='hero-body-content white'>
                    <h2 className='hdr-4'>We are R.P.A certified!</h2>
                    <h1 className='hdr-1'>Reliable.<br />Professional.<br />Affordable.</h1>
                    <p className='sbt-2'>First impressions are everything in any business. Let us take care of that for you! You are at the right place to work with a professional team that cares about your business and your success.</p>
                    <ul>
                        <li className='sbt-2'>Easy Online Booking</li>
                        <li className='sbt-2'>Fully Insured</li>
                        <li className='sbt-2'>Fully Equipped & Supplied</li>
                        <li className='sbt-2'>Touch Free Payment</li>
                    </ul>
                    <Button content="Let's get started!" route="#contact" invert={true} />
                </div>

            </div>
        </div>
    )
}

export default Hero