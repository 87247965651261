import React from 'react'
import Header from '../../components/header/header'
import Hero from '../../components/hero/hero'
import "./home.css"
import img from "./cleanimage.jpg"
import Partner from '../../components/partner/partner'
import Testimonial from '../../components/testimonial/testimonial'


const Home = () => {
    return (
        <div id="home-body-outer" className='outer'>
            <Header/>

            <Hero img={img}/>

            <Partner/>

            <Testimonial/>

        </div>
    )
}

export default Home