import Home from './pages/home/home';

function App() {
  return (
    <>
      <Home />
    </>
  );
}

export default App;
