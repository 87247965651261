import React from 'react'
import { Link } from 'react-router-dom'
import Button from '../button/button'
import "./footer.css"
import logo from "../navigation/cleaninglogo.png"
import Contact from '../contact/contact'

const Footer = () => {
    return (
        <div id="footer-outer" className='outer'>

            <div id='footer-hero'>
                <div id='footer-hero-title'>
                    <h1 className='hdr-1 white'>We clean it all.</h1>
                    <p className='white sbt-2'>Houses | Offices | Apartments | Clinics | Commercial</p>
                </div>
                <Button route="#contact" content="Contact Us" />
            </div>

            <Contact />

            <div id="footer-inner-body" className='bg-orange' >


                <div className='inner' id="footer-inner-body-content">

                    <div className='line'></div>

                    <div id="footer-content">

                        <img src={logo} />

                        <div id="footer-content-title">
                            <p className='white sbt-2'>Life is too short to worry about cleaning.</p>
                            <h2 className='white'>Yireh Cleaning Services, LLC.</h2>
                            <p className='white sbt-2'>Let us worry about it.</p>
                        </div>

                        <div id="footer-content-links">
                            <Link to="/" className='hdr-5 underline'>Home</Link>
                            <a href="#services" className='hdr-5 underline'>Services</a>
                            <a href="#clients" className='hdr-5 underline'>Our Clients</a>
                            <a href="#clients" className='hdr-5 underline'>Contact Us</a>
                        </div>

                    </div>

                    <div className='line'></div>

                </div>

            </div>

        </div>
    )
}

export default Footer

/*



*/