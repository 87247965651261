import React, { useState, useRef } from 'react'
import Button from '../button/button'
import Select from 'react-select';
import "./contact.css"
import { render } from '@testing-library/react';
import emailjs from '@emailjs/browser';
import { motion } from "framer-motion"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'


const Contact = () => {


  //submit ticket
  /*
const handleInputs = (e) => {
    let item = this.state
    item[e.target.name] = e.target.value
    this.setState({ [e.target.name]: e.target.value })
  }
  */


  const form = useRef();


  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [service, setService] = useState('');
  const [submit, setSubmit] = useState(false)

  const handleInputs = (e) => {
    let targetValue = e.target.name
    switch (targetValue) {
      case 'to_name':
        setName(e.target.value)
        break;
      case 'to_service':
        setService(e.target.value)
        break;
      case 'to_email':
        setEmail(e.target.value)
        break;
      default:
        alert("there was an error")
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("submitting email")
    emailjs.sendForm('service_7ex1j1x', 'template_ndlnbvo', form.current, 'C1ltZfMXbIzanui8M')
      .then((result) => {
        console.log(result.text);
        clearInputs()
        handleSubmitTimer()
      }, (error) => {
        console.log(error.text);
      });
  }

  const clearInputs = () => {
    setName('')
    const $select = document.querySelector('#lolol');
    $select.value = 'none'
    setEmail('')
  }

  const handleSubmitTimer = () => {
    setSubmit(true)
    setTimeout(() => {
      setSubmit(false)
    }, 5000);
  }

  //faCircleCheck


  return (
    <div id="contact" className='outer' >

      <div id="contact-body-inner" className='inner'>

        {submit ? <FontAwesomeIcon id="contact-success" icon={faCircleCheck} size="4x" /> : <h1 className='hrd-3'>Let's talk!</h1>}


        <div id="contact-content">

          <form ref={form} onSubmit={handleSubmit}>

            <div className='contact-content-divs'>
              <label className='hdr-4'>Hello, my name is</label>
              <input id="input-name" value={name} onChange={handleInputs} required placeholder='Type Here' type="text" name="to_name" />
            </div>

            <div className='contact-content-divs'>
              <label className='hdr-4'>{!submit ? "I am looking for:" : "and I am looking for"}</label>
              <select id="lolol" name="to_service" required onChange={handleInputs}>
                <option value="none" selected disabled hidden>Select an Option</option>
                <option value="apartment">a cleaner apartment</option>
                <option value="office">a cleaner office</option>
                <option value="clinic">a cleaner clinic</option>
                <option value="other">other</option>
              </select>
            </div>

            <div className='contact-content-divs'>
              <label className='hdr-4'>{!submit ? "Your new cleaning company would like to follow up with you at:" : "Your new cleaning company would like to follow up with you at"}</label>
              <input id="input-email" value={email} onChange={handleInputs} required placeholder='Your email' type="email" name="to_email" />
            </div>

          </form>



        </div>
        <motion.button
          id="contact-button"
          className={submit ? 'hdr-5 bg-green white' : 'bg-orange hdr-5 white'}
          whileHover={submit ? { scale: 1 } : { scale: 1.04 }}
          whileTap={{ scale: 1 }}
          onClick={handleSubmit}>{submit ? "We will reach out to you shortly!" : "Submit Ticket!"}</motion.button>


      </div>

    </div>
  )
}

export default Contact

/*

          

            <div>

              

              

            </div>

            <div>
              <h2 className='hdr-4'>Your new cleaning company would like to follow up with you at</h2>
              <input id="input-email" placeholder='Your email' type="email" name="to_email" onChange={handleInputs} />
            </div>

            <button onClick={handleSubmit}>Submit Ticket!</button>

          </div>



*/