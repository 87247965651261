import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Button from '../button/button'
import "./navigation.css"
import clean from "./cleaninglogo.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import inverted from './logoinvertedpng.png'
import { motion } from 'framer-motion'
const desktop = (

    <div id="navigation-links">

        <Link to="/" className='hdr-5 white hoverable'>Home</Link>

        <a href='#services' className='hdr-5 white hoverable'>Services</a>

        <a href='#clients' className='hdr-5 white hoverable'>Our Clients</a>


        <a href='#contact' className='hdr-5 white hoverable'>Contact Us</a>

    </div>

)

const dropdownMenuVars = {
    "open": {
        x: 0,
        opacity: 1
    },
    "close": {
        x: "100%",
        opacity: 0
    }
}

const Navigation = () => {

    const [menutoggle, setMenuToggle] = useState(false)
    const MenuHandler = () => { setMenuToggle(!menutoggle) }

    return (
        <div className='outer'>

            <div id="navigation-body" className='inner'>

                <div id="navigation-logo">
                    <img src={clean} />
                </div>

                {desktop}

                <div id="navigation-burger">

                    <motion.div
                        variants={dropdownMenuVars}
                        initial="close"
                        animate={menutoggle ? "open" : "close"}
                        id="navigation-dropdown-mobile-menu-outer">

                        <div id="navigation-dropdown-mobile-menu-inner">

                            <div id="navigation-dropdown-mobile-menu-icon-container">

                                <FontAwesomeIcon id="navigation-dropdown-mobile-menu-exit" icon={faXmark} size="2x" onClick={MenuHandler} />
                                
                            </div>

                            <div id="navigation-mobile-links-list">

                                <Link to="/" className='hdr-3 orange hoverable' onClick={MenuHandler}>Home</Link>

                                <a href='#services' className='hdr-3 orange hoverable' onClick={MenuHandler}>Services</a>

                                <a href='#clients' className='hdr-3 orange hoverable' onClick={MenuHandler}>Our Clients</a>


                                <a href="#contact" className='hdr-3 orange hoverable' onClick={MenuHandler}>Contact Us</a>

                            </div>

                            <div id="navigation-dropdown-mobile-menu-logo-container">
                                <img src={inverted} />
                            </div>

                        </div>

                    </motion.div>

                    <div id="navigation-mobile-menu" onClick={MenuHandler}>
                        <FontAwesomeIcon icon={faBars} size="2x" />
                    </div>
                </div>

            </div>

        </div>
    )
}
//<Link to="/" className='hdr-5 white'>Test</Link>
export default Navigation