import React from 'react'
import "./header.css"
import { motion } from "framer-motion"
import { Link } from 'react-router-dom'

const Header = () => {
    return (
        <div className='outer' id="header-body-outer">
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{
                    delay: 1, type: 'spring', bounce: .5
                }}
                className='inner' id="header-body-inner">
                    
                <h1 className='hdr-1'>Life is too short to worry about <span>cleaning.</span></h1>
                
                <p className='sbt-3'>Let us worry about it</p>

            </motion.div>
        </div>
    )
}

export default Header