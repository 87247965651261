import React from 'react'
import "./partner.css";
import alt from "./alt.png"
import berk from "./berk.png"
import angel from "./angel.png"
import air from "./air.png"

const partners = [
    {
        link: alt,
        size: "8rem",
        homelink: "https://www.altrecoverygroup.com/"
    },

    {
        link: angel,
        size: "14rem",
        homelink: "https://www.angelsmiledentalgroup.com/"
    },
    {
        link: berk,
        size: "8rem",
        homelink: "https://www.bhhscalifornia.com/"
    },
    {
        link: air,
        size: "12rem",
        homelink: "https://www.airbnb.com/?c=.pi0.pk18100569292_139839607829&ghost=true&gclid=Cj0KCQjw08aYBhDlARIsAA_gb0eBynimi1jTFY5g6PD5Nfmkh8rPdbvcVz1fDr69E7aQIXnbjY-y190aAkXqEALw_wcB"
    }
]

const Partner = () => {
    return (
        <div className='outer partner-outer' id="clients">

            <div className='inner partner-inner'>

                <h1 className='hdr-6'>Some of our <span>beloved Clientele!</span></h1>

                <div className='partner-inner-logos'>

                    {
                        partners.map((item, index) => (

                            <a href={item.homelink} target="_blank">
                                <img className='black' key={index} style={{ width: item.size }} src={item.link} />
                            </a>
                        ))
                    }
                </div>

            </div>

        </div>
    )
}

export default Partner