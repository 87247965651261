import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from "framer-motion"
import "./button.css"

const ORANGE = 'bg-orange'

const Button = (props) => {
  return (
    <motion.div
      whileHover={{ scale: 1.04 }}
      whileTap={{ scale: 1 }}
      className={props.invert ? 'button dropshadow bg-white' : 'button bg-orange'}>
      <a className={props.invert ? 'hdr-5 black' : 'hdr-5 white'} href={props.route}>{props.content}</a>
    </motion.div>
  )
}

export default Button