import React from 'react'
import "./testimonial.css"

const Testimonial = () => {
    return (
        <div className='outer testimonial-outer bg-light-gray'>

            <div className='inner testimonial-inner'>
                <h1 className='orange hdr-1'>“They are great! Extremely professional, work really fast, and pay attention to details. Highly recommend!”</h1>
                <h4 className='orange sbt-3'>- Alt Recovery Group</h4>
            </div>

        </div>
    )
}

export default Testimonial